import React from 'react'
import Welcome from '../../components/Welcome/Welcome'
import StatsSection from '../../components/StatsSection/StatsSection'
import Piechart from '../../components/Piechart/Piechart'
import Traffic from '../../components/Traffic/Traffic'
import './Dashboard.css'

function Dashboard() {
    return (
        <div className='Dashboard'>
            <section>
                <Welcome message = ', Welcome back' />
            </section>
            <section> 
                <StatsSection />
            </section>
            <section>
                <Piechart/>
            </section>
            <section>
                <Traffic/>
            </section>

        </div>
    )
}

export default Dashboard