import React from 'react'
import Statistics from './Statistics'

function Visitstats() {
  return (
    <div>
         <Statistics
                count = '5k'
                type = 'Website visits'
                bg = '#f5f2ff'
                color = '#342662'
            />
    </div>
  )
}

export default Visitstats