import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx';
import ContentBootcamp from '../ContentBootcamp/ContentTable';
import Entries from '../Entries/Entries';
import ContentHead from '../ContentHead';
import '../EventDataTable/EventDataTable.css'

const ContentDefault = (props) => {

    const [searchQuery, setSearchQuery] = useState('')


    const baseUrl = props.url
    const [data, setData] = useState([])

    useEffect(() => {
        axios.get(baseUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                setData(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])


    console.log(data.phone)

    function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf)
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf
    }

    function handlesave() {
        const worksheet = XLSX.utils.json_to_sheet(data)
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const filename = 'data.xlsx'

        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        FileSaver.saveAs(blob, filename);
    }

    // ends

    function handleSearch(event) {
        setSearchQuery(event.target.value)
    }

    const filteredData = data.filter(items => {
        return items.email.toLowerCase().includes(searchQuery.toLowerCase())
    })

    const registrees = filteredData.map((item, index) => {
        return (
            <ContentBootcamp
                key={item._id}
                id={item._id}
                sn={index + 1}
                fullname={item.fullname}
                email={item.email}
                number={item.phone}
                gender={item.gender}
                student={item.student}
                knowledge={item.rate}
                hours={item.hours}
            />
        )
    })


    const entries = data.length

    useEffect(() => {
        localStorage.setItem('contentregs', entries)
      }, [entries])


    return (
        <div className='EventDataTable'>
        <Entries entries={entries} />
        <span>
            {/* {data.interest.length} */}
        </span>
        <main>
        <button className='xls' onClick={handlesave} >Download Sheet</button>
        </main>
        <section >
        <input
          type='text'
          value={searchQuery}
          onChange={handleSearch}
          className='search'
          placeholder='Search user ...'
        />
        </section>
        <ContentHead />
        {registrees}
      </div>
    )
}

export default ContentDefault;