import React from 'react'
import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import './Sidebar.css'

function Sidebar() {
    const [navOpen, setNavOpen] = useState(false)

    const isMobile = window.matchMedia('(max-width: 670px)').matches

    useEffect(()=>{
        isMobile ? setNavOpen(false) : setNavOpen(true)
    },[])

    function openNav(){
        setNavOpen(!navOpen)
    }

    function closeNav(){
        setNavOpen(!navOpen)
    }

  return (
    <div >
        <img alt='' src='/images/menu.png' id='menu' onClick={openNav} />
        <span  className='sidebar' style={{
            transform : navOpen ? 'translateX(0%)' : 'translateX(-100%)',
            transition : navOpen ? '.5s linear' : '.5s linear'
        }} > 
        <img alt='' src='/images/close.png' id='close' onClick={closeNav} />
        <img alt='logo' src='/images/logo.png' width='100px' />
        <nav>
            <ul>
                
                <NavLink 
                 to= '/Main/dashboard' 
                style={{textDecoration: 'none', color:  '#535151'}}
                >
                    <li >
                    Dashboard
                    </li>
                </NavLink>
                
               <NavLink 
               to= '/Main/event_data' 
               style={{textDecoration: 'none', color: '#535151'}}
               >
                <li>
                   Event Data
               </li>
               </NavLink>
               <NavLink 
               to= '/Main/content_data' 
               style={{textDecoration: 'none', color: '#535151'}}
               >
                <li>
                   Content Data
               </li>
               </NavLink>
               <NavLink 
               to= '/Main/web2_data' 
               style={{textDecoration: 'none', color: '#535151'}}
               >
                <li>
                   Web2 Data 
               </li>
               </NavLink>
               <NavLink 
               to= '/Main/web3_data' 
               style={{textDecoration: 'none', color: '#535151'}}
               >
                <li>
                   Web3 Data
               </li>
               </NavLink>
            
                <li>Blog</li>

                <NavLink 
               to= '/logout' 
               style={{textDecoration: 'none', color: '#535151'}}
               >
                <li>
                   Logout
               </li>
               </NavLink>
            </ul>
        </nav>
        </span>
      
    </div>
  )
}

export default Sidebar