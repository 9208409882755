import React from 'react'
import './Welcome.css'

function Welcome(props) {
  return (
    <div className='welcome'>
        <h1>
             Hello {props.message}.
        </h1> 
        </div>
  )
}

export default Welcome