import React from 'react'
import '../../components/EventTable/EventTable.css'

const ContentTable = (props) => {
  const knowledge = props.knowledge
  return (
    <div>
         <div className='Etable'>
        <ul key={props.id} className='row'>
            <li id='sn'>{props.sn}</li>
            <li id='evenameB'>{props.fullname} </li>
            <li id='mail'>{props.email}</li>
            <li id='eveNo'>{props.number}</li>
            <li>{props.gender}</li>
            <li >{props.student}</li>
            <li>{knowledge.slice(0,6)}</li>
            <li >{props.hours}</li>
        </ul>
     </div>
    </div>
  )
}

export default ContentTable