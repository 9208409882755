import './App.css';
import Main from './pages/Maindisplay/Main';
import Login from './pages/Login/Login';
import Dashboard from './pages/dashboard homepage/Dashboard';
import EventData from './pages/EventData page/EventData';
import { Routes, Route } from 'react-router-dom';
import ContentPage from './pages/ContentPage';
import Web2DevPage from './pages/Web2DevPage';
import Web3DevPage from './pages/Web3DevPage';
import Total from './components/total';

function App() {
  return (
    <>
      {/* <Main/> */}
      {/* <Login /> */}
      <Routes>
        <Route path='/' element={<Login />} />
        <Route  path='/Main' element={<Main />} >
          <Route index element={<Dashboard/>} />
          <Route path='dashboard' element = {<Dashboard/>} />
          <Route path='event_data'  element ={<EventData/>} />
          <Route path='content_data'  element ={<ContentPage/>} />
          <Route path='web2_data'  element ={<Web2DevPage/>} />
          <Route path='web3_data'  element ={<Web3DevPage/>} />
          <Route path='total'  element ={<Total/>} />
        </Route>
          <Route path='logout'  element ={<Login/>} />
      </Routes>

    </>
  );
}

export default App;
