import React from 'react'
import './Navbar.css'
import { useState, useEffect } from 'react'

function Navbar() {

  const [name, setName] = useState()

  useEffect(()=>{
    const username = localStorage.getItem('username')
    setName(username)
  },[])

  return (
    <div className='navbar'>
        <main>
            <img alt='profilepic' src='/images/avatar_default.jpg' width= '50px' />
            <h2>{name}</h2>
        </main>
    </div>
  )
}

export default Navbar