import React from 'react'
import './Traffic.css'

function Traffic() {
  return (
    <div className='traffic'>
        <h2>
            Traffic by Site 
        </h2>
        <article>
            <main>
                <img src='/images/fb.png' alt='traff' />
                <strong>
                    323.23k
                </strong>
                <small>
                    Facebook
                </small>
            </main>
            <main>
                <img src='/images/fb.png' alt='traff' />
                <strong>
                    323.23k
                </strong>
                <small>
                    Facebook
                </small>
            </main>
            <main>
                <img src='/images/fb.png' alt='traff' />
                <strong>
                    323.23k
                </strong>
                <small>
                    Facebook
                </small>
            </main>
            <main>
                <img src='/images/fb.png' alt='traff' />
                <strong>
                    323.23k
                </strong>
                <small>
                    Facebook
                </small>
            </main>
        </article>
    </div>
  )
}

export default Traffic