import React from 'react'
import DevDefault from '../components/DevDefault/DevDefault'

const Web3DevPage = () => {
  return (
    <div className='bootcampPage'>
    <DevDefault url = 'https://blockchainunn.org/api/devbootcamp' stack = 'Web 3' />
    </div>
  )
}

export default Web3DevPage