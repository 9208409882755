import React from 'react'
import './EventTableHead/EventTableHead.css'

const DevHead = () => {
    return (
        <div className='Etablehead'>
            <ul>
                <li id='snH'>SN</li>
                <li id='evename'>Name</li>
                <li id='mailH'>Email Address</li>
                <li id='eveNoH'>Phone Number</li>
                <li>Gender</li>
                <li>Stream</li>
                <li>Knowledge</li>
                <li>Rate</li>
                <li>Hours</li>
            </ul>
        </div>
    )
}

export default DevHead