import React from 'react'
import './Entries.css'


function Entries(props) {
  return (
    <small className='entries'>
    No of Entries: - {props.entries} 
  </small>
  )
}

export default Entries