import React, { useEffect, useState } from 'react'
import Statistics from './Statistics'

function Eventstats() {

  const [event_no, setEvent_no] = useState()
  const [conBootcamp_no, setConBootcamp_no] = useState()
  const [devBootcamp_no, setDevBootcamp_no] = useState()

  useEffect(()=>{
    const event_no = localStorage.getItem('eventregs')
    const conBootcamp_no = localStorage.getItem('contentregs')
    const devBootcamp_no = localStorage.getItem('devregs')
    setEvent_no(event_no)
    setConBootcamp_no(conBootcamp_no)
    setDevBootcamp_no(devBootcamp_no)
  },[])

  return (
    <div>
         <Statistics
                count = {event_no}
                type = 'Event attendees'
                bg = '#f9f6ed'
                color = '#5a4c1f'
            />
    </div>
  )
}

export default Eventstats