import React from 'react'
import { useState, useEffect } from 'react'
import Statistics from './Statistics'

function Membersstats() {

  const [event_no, setEvent_no] = useState()
    const [conBootcamp_no, setConBootcamp_no] = useState()
  const [devBootcamp_no, setDevBootcamp_no] = useState()

  useEffect(()=>{
    const event_no = localStorage.getItem('eventregs')
    const conBootcamp_no = localStorage.getItem('contentregs')
    const devBootcamp_no = localStorage.getItem('devregs')
    setEvent_no(event_no)
    setConBootcamp_no(conBootcamp_no)
    setDevBootcamp_no(devBootcamp_no)
  },[])

  console.log(typeof(conBootcamp_no))

  const total = (conBootcamp_no * 1) + (devBootcamp_no * 1) + (event_no * 1)

    return (
        <div>
            <Statistics
                count = {total}
                type = ' Members'
                bg = '#d0f7c7'
                color = '#388e3c'
            />
        </div>
    )
}

export default Membersstats