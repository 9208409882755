import React from 'react'
// import { useState, useEffect } from 'react'
// import axios from 'axios'

const Total = () => {
    // const [data, setData] = useState([])

    // const baseUrl = 'https://blockchainunn.org/api/data'
    // const baseUrl2 = 'http://blockchainunn.org/api/contentbootcamp'
    // const baseUrl3 = 'http://blockchainunn.org/api/devbootcamp'

    // useEffect(() => {
    //     axios.get(baseUrl2, {
    //         headers: {
    //             'Content-Type': 'application/json'
    //         }
    //     })
    //         .then(response => {
    //             setData(response.data)
    //         })
    //         .catch(error => {
    //             console.log(error)
    //         })
    // }, [])

    // console.log(data.email)
    
  return (
    <div>Total</div>
  )
}

export default Total