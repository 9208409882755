import React from 'react'
import Membersstats from './../Statistics/Membersstats'
import Visitstats from './../Statistics/Visitstats'
import Eventstats from './../Statistics/Eventstats'
import './StatsSection.css'


function StatsSection() {
  return (
    <div className='statsec'>
        <main>
            <Membersstats/>
        </main>
        <main>
            <Eventstats/>
        </main>
        <main>
            <Visitstats/> 
        </main>
    </div>
  )
}

export default StatsSection