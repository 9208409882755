import React from 'react'
import './EventTableHead.css'

function EventTableHead() {
  return (
         <div className='Etablehead'>
            <ul>
                <li id='snH'>SN</li>
                <li id='evename'>Name</li>
                <li id='mailH'>Email Address</li>
                <li id='eveNoH'>Phone Number</li>
                <li>Gender</li>
                <li id='careerH' >Career</li>
                <li >Student</li>
                <li>Residence</li>
                <li  >Day 1&2</li> 
            </ul>
     </div>
  )
}

export default EventTableHead