import React from 'react'
import ContentDefault from '../components/ContentDefault/ContentDefault'

const ContentPage = () => {
    const url = 'https://blockchainunn.org/api/contentbootcamp'
  return (
    <ContentDefault url = {url} />
  )
}

export default ContentPage