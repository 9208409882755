import React, { useEffect, useState } from 'react'
import Welcome from '../../components/Welcome/Welcome'
import EventDataTable from '../../components/EventDataTable/EventDataTable'
import './EventData.css'

function EventData() {

  
    const [name, setName] = useState()

    useEffect(()=>{
      const username = localStorage.getItem('username')
      setName(username)
    },[])


  return (
    <div className='eventpage'>
        <section>
            <Welcome message =  {name} />
        </section>
        <section className='eventDiv'> 
            <EventDataTable/>
        </section>
    </div>
  )
}

export default EventData