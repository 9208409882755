import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import './EventTable.css'

function EventTable(props) {
  return (
     <div className='Etable'>
        <ul key={props.id} className='row'>
            <li id='sn'>{props.sn}</li>
            <li id='evenameB'>{props.fullname} </li>
            <li id='mail'>{props.email}</li>
            <li id='eveNo'>{props.number}</li>
            <li>{props.gender}</li>
            <li id='career'>{props.career}</li>
            <li >{props.student}</li>
            <li>{props.residence}</li>
            <li >{props.attendance}</li>
        </ul>
     </div>
  )
}

export default EventTable