import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'
import { Outlet } from 'react-router-dom'
import './Main.css'


function Main() {
    
    return (
                
        <div className='mainpage'>
            <section className='main_sidebar'>
                <Sidebar />
            </section>
            <section className='mainpage_body'>
                <main className='main_navbar'>
                    <Navbar />
                </main>
                <main className='mainpage_body_data'>
                <Outlet/>
                </main>
            </section>
        </div>
    )
}

export default Main