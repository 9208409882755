import React, { useEffect } from 'react'
import './Login.css'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

function Login() {

    const navigate = useNavigate()

    const [formInfo, setFormInfo] = useState({
        name : '', 
        password: '' 
    })

    function handleInput(e){
        setFormInfo({
            ...formInfo,
            [e.target.name] : e.target.value
        })
    }
console.log(formInfo.password)

    const [isvisible, setIsvisible] = useState(false)

    function visible(){
        setIsvisible(!isvisible)
    }


    const adminUser = formInfo.name
    const adminPW = 'Bunn&2023'

    useEffect(()=>{
        localStorage.setItem('username', adminUser)
    },[adminUser])


    function Login(e){
        e.preventDefault()
        if(formInfo.password ===  adminPW){
            navigate('/Main')
        }
        
    }

  return (
    <div className='login'>
         <header>
        <h1>
            Admin Log in Page
        </h1>
    </header>
    <section>
        <form onSubmit={Login}>
    <main>
            <div>
                <label htmlFor='name'>Email</label>
                <input 
                type="text" 
                name="name" 
                placeholder='Username'
                value={formInfo.name}
                onChange={handleInput}
                />
            </div>
            <div style={{position: "relative"}}>
                <label htmlFor='password'>Password</label>
                <input 
                type= {isvisible ? 'text' : 'password'} 
                name='password'
                placeholder='Password'
                value={formInfo.password}
                onChange={handleInput}
                />
            <img src= {isvisible ? "./images/icons8-invisible-50.png" :"./images/icons8-blind-50.png"} alt="" id="visible" className='viewpw' onClick={visible} />
            </div>
        <div>
            <button type='submit'>Log in </button>
        </div>
        </main>
        </form>
    </section>
    </div>
  )
}

export default Login