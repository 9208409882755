import React from 'react'
import './Statistics.css'

function Statistics(props) {
  return (
    <div className='Stats' style={{backgroundColor: props.bg, color: props.color }}>
        <img alt='user' src='/images/user.png' />
        <h2>{props.count}</h2>
        <small>{props.type}</small>
    </div>
  )
}

export default Statistics