import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx';
import DevBootcampTable from '../DevBootcamp/DevBootcampTable';
import Entries from '../Entries/Entries';
import DevHead from '../DevHead';
import '../EventDataTable/EventDataTable.css'

const DevDefault = (props) => {

  const [searchQuery, setSearchQuery] = useState('')


  const baseUrl = props.url
  const [data, setData] = useState([])

  useEffect(() => {
      axios.get(baseUrl, {
          headers: {
              'Content-Type': 'application/json'
          }
      })
          .then(response => {
              setData(response.data)
          })
          .catch(error => {
              console.log(error)
          })
  }, [])

  function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf)
      for (let i = 0; i < s.length; i++) {
          view[i] = s.charCodeAt(i) & 0xFF;
      }
      return buf
  }

  function handlesave() {
      const worksheet = XLSX.utils.json_to_sheet(web2_data)
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      const filename = 'data.xlsx'

      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
      const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
      FileSaver.saveAs(blob, filename);
  }

  // ends

  function handleSearch(event) {
      setSearchQuery(event.target.value)
  }

  const web2_data = data.filter(user=> user.interest === props.stack)

  const filteredData = web2_data.filter(items => {
      return items.email.toLowerCase().includes(searchQuery.toLowerCase())
  })

  const registrees = filteredData.map((item, index) => {
      return (
          <DevBootcampTable
              key={item._id}
              id={item._id}
              sn={index + 1}
              fullname={item.fullname}
              email={item.email}
              number={item.phone}
              gender={item.gender}
              interest={item.interest}
              student={item.student}
              knowledge={item.rate}
              hours={item.hours}
          />
      )
  })


  const entries = web2_data.length
  const devEntries = data.length
  useEffect(() => {
    localStorage.setItem('devregs', devEntries)
  }, [devEntries])



  return (
    <div className='EventDataTable'>
        <Entries entries={entries} />
        <span>
            {/* {data.interest.length} */}
        </span>
        <main>
        <button className='xls' onClick={handlesave} >Download Sheet</button>
        </main>
        <section >
        <input
          type='text'
          value= {searchQuery}
          onChange={handleSearch}
          className='search'
          placeholder='Search user ...'
        />
        </section>
        <DevHead />
        {registrees}
      </div>
  )
}

export default DevDefault